const orderReducer = (state = [], action) => {
  switch (action.type) {
    case 'ORDERPRINT':
      return action.payload.data
    default:
      return state
  }
}

export default orderReducer
