import { Cookie } from '@/utils/Helpers'

export const cartReducer = (state = { quantity: 0 }, action) => {
  switch (action.type) {
    case 'SET_CART': {
      const cart: any[] = Cookie.get('cart') || []
      const item = cart.find((item) => {
        return (
          item.product_id === action.payload.product_id &&
          item.variant_id === action.payload.variant_id
        )
      })
      if (item) {
        cart[cart.indexOf(item)].quantity = action.payload.quantity
        Cookie.set('cart', [...cart], 7)
      } else {
        const product = {
          product_id: action.payload.product_id,
          variant_id: action.payload.variant_id,
          quantity: action.payload.quantity,
        }
        Cookie.set('cart', [...cart, product], 7)
      }

      const { quantity } = action.payload
      return { ...state, quantity }
    }
    case 'ADD_TO_CART': {
      const cart: any[] = Cookie.get('cart') || []
      const item = cart.find((item) => {
        return (
          item.product_id === action.payload.product_id &&
          item.variant_id === action.payload.variant_id
        )
      })
      if (item) {
        cart[cart.indexOf(item)].quantity += action.payload.quantity
        Cookie.set('cart', [...cart], 7)
      } else {
        const product = {
          product_id: action.payload.product_id,
          variant_id: action.payload.variant_id,
          quantity: action.payload.quantity,
        }
        Cookie.set('cart', [...cart, product], 7)
      }

      const quantity = state.quantity + action.payload.quantity

      return { ...state, quantity }
    }

    case 'REMOVE_ALL_FROM_CART': {
      const cart: any[] = Cookie.get('cart') || []
      const item = cart.find((item) => {
        return (
          item.product_id === action.payload.product_id &&
          item.variant_id === action.payload.variant_id
        )
      })

      if (item) {
        Cookie.set('cart', [...cart.filter((product) => product !== item)], 7)
        const quantity = state.quantity - item.quantity
        return { ...state, quantity }
      }
      return state
    }

    case 'REMOVE_FROM_CART': {
      const cart: any[] = Cookie.get('cart') || []
      const item = cart.find((item) => {
        return (
          item.product_id === action.payload.product_id &&
          item.variant_id === action.payload.variant_id
        )
      })
      if (item) {
        cart[cart.indexOf(item)].quantity -= action.payload.quantity
        if (cart[cart.indexOf(item)].quantity <= 0) {
          Cookie.set('cart', [...cart.filter((product) => product !== item)], 7)
        } else {
          Cookie.set('cart', [...cart], 7)
        }
      }
      const quantity = state.quantity - action.payload.quantity
      return { ...state, quantity }
    }

    case 'PURGE_CART': {
      let quantity = 0
      if (!Cookie.get('cart')) {
        Cookie.set('cart', [], 7)
      } else {
        const cart = Cookie.get('cart').filter((element) => {
          return element.quantity > 0
        })
        cart.forEach((product) => {
          quantity += product.quantity
        })
        Cookie.set('cart', cart, 7)
      }
      return { ...state, quantity }
    }
    case 'CART_INITIAL': {
      var quantity = 0
      if (!Cookie.get('cart')) {
        Cookie.set('cart', [], 7)
      } else {
        Cookie.get('cart').forEach((element) => {
          quantity += element.quantity
        })
      }
      return { ...state, quantity }
    }

    default:
      return state
  }
}

export const orderDetails = (state = {}, action) => {
  switch (action.type) {
    case 'GET_ORDER_DETAILS':
      return action.payload
    default:
      return state
  }
}
