import {
  compose,
  Config,
  ConfigStyle,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  display,
  verticalAlign,
  overflow,
  RequiredTheme,
  ResponsiveValue,
  styleFn,
  system,
  Theme,
  ThemeValue,
} from 'styled-system'
import { rem } from 'polished'

/* Typeface */
// Constants
const BASE_FONT_SIZE = '16px'

// Interface
export interface TypeProps<ThemeType extends Theme = RequiredTheme> {
  type?: ResponsiveValue<string | {}, ThemeType>
}

// Helpers
export const pxToRem = (pxUnit: number, base = BASE_FONT_SIZE) => rem(pxUnit, base)
export const remToPx = (remUnit: number, base = BASE_FONT_SIZE) => remUnit * Number.parseFloat(base)

// Maps
export const FontSizeMap = {
  xs: pxToRem(7),
  s: pxToRem(14),
  m: pxToRem(Number.parseInt(BASE_FONT_SIZE)),
  l: pxToRem(19),
  xl: pxToRem(28),
  xxl: pxToRem(40),
  xxxl: pxToRem(50),
}

export const FontWeightMap = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
}

export const FontFamilyMap = {
  title: '"Playfair Display", serif',
  default: 'Open Sans, sans-serif',
}

// Styled system declarations
const FontFamilyConfig: ConfigStyle = {
  property: 'fontFamily',
  scale: 'fonts',
}

// Styled system configurations
const TypePropsConfig: Config = {
  type: FontFamilyConfig,
}

export const type: styleFn = system(TypePropsConfig)

/* Colors */
// Interface
export interface TextColorProps<ThemeType extends Theme = RequiredTheme, TVal = ThemeValue<'colors', ThemeType>> {
  textColor?: ResponsiveValue<TVal, ThemeType>
}

export interface BackgroundColorProps<ThemeType extends Theme = RequiredTheme, TVal = ThemeValue<'colors', ThemeType>> {
  bg?: ResponsiveValue<TVal, ThemeType>
  backgroundColor?: ResponsiveValue<TVal, ThemeType>
}

export interface ColorProps<ThemeType extends Theme = RequiredTheme, TVal = ThemeValue<'colors', ThemeType>>
  extends TextColorProps<ThemeType, TVal>,
    BackgroundColorProps<ThemeType, TVal> {}

// Maps
export const ColorsMap = {
  primary: '#509050', //green
  secondary: '#336032', //darkGreen
  white: '#FFFFFF',
  black: '#000000',
  textDefault: '#a8a8a8', //grey
  background: '#f7f7f7', //backgroundLightGrey
  lightGrey: '#f3f3f3', //lightGrey
  shadowGrey: '#f3f3f3', //mediumGrey
  brownishGrey: '#5f5f5f', //brownishGrey
  fontHover: '#000000', //black
  success: '#00FF00',
  warning: '#FFFF00',
  error: '#E30613',
  info: '#0000FF',
  link: '#4377f2', //dodger-blue
}

// Styled system declarations
const ColorConfig: ConfigStyle = {
  property: 'color',
  scale: 'colors',
}

const BackgroundConfig: ConfigStyle = {
  property: 'background',
  scale: 'colors',
}

const BackgroundColorConfig: ConfigStyle = {
  property: 'backgroundColor',
  scale: 'colors',
}

// Styled system configurations
const ColorPropsConfig: Config = {
  textColor: ColorConfig,
  bg: BackgroundConfig,
  backgroundColor: BackgroundColorConfig,
}

export const color: styleFn = system(ColorPropsConfig)

/* Borders */
// Maps
export const BorderRadiusMap = {
  primary: '0px',
  circle: '50%',
}

// Theme export
export const ThemeConfig = {
  breakpoints: ['578px', '986px', '1400px'],
  colors: ColorsMap,
  fonts: FontFamilyMap,
  fontSizes: FontSizeMap,
  fontWeights: FontWeightMap,
  radii: BorderRadiusMap,
}

/* Layout */
// Helpers
export const layoutExclSize: styleFn = compose(
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  display,
  verticalAlign,
  overflow
)

export const device = {
  mobileM: '550px',
  mobileS: '450px',
  mobile: '700px',
  tablet: '1000px',
  midleDesktop: '1250px',
  desktop: '2000px',
}
