export const retrieve = (url: RequestInfo, method = 'GET', token = null, params = null) => {
  const headers = {
    'NeKommerce-Access-Key': 'PMTbdRme52j3bUOGAo9HW48VW6Qayx8fjuw9ZDvF',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  if (params) {
    return fetch(`${process.env.NEXT_PUBLIC_BACKEND_HOST}${url}`, {
      headers,
      method,
      body: JSON.stringify(params),
    })
  }

  return fetch(`${process.env.NEXT_PUBLIC_BACKEND_HOST}${url}`, {
    headers,
    method,
  })
}
