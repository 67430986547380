import { Cookie } from '../../utils/Helpers'

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case 'USER_DATA': {
      state = action.payload.data
      return { ...state }
    }

    default:
      return state
  }
}
