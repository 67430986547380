interface ProductState {
  payload: any[]
  count: number
}

const productReducer = (
  state: ProductState = {
    payload: [],
    count: null,
  },
  action
) => {
  switch (action.type) {
    case 'GET_PRODUCTS':
      return {
        payload: action.payload.data,
        count: action.payload.count,
        total: action.payload.total,
      }
    case 'CLEAR_PRODUCTS':
      return {
        payload: [],
        count: null,
        total: null,
      }
    default:
      return state
  }
}

export default productReducer
