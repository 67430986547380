import Cookies from 'js-cookie'

export class Cookie {
  static get(key: string) {
    const content = Cookies.get(key)
    return content && this.isJson(content) ? JSON.parse(content) : null
  }

  static has(key: string) {
    const content = Cookies.get(key)
    return !!content
  }

  static text(key: string) {
    return Cookies.get(key) ? Cookies.get(key) : null
  }

  static set(key: string, value: any, expires) {
    return Cookies.set(key, JSON.stringify(value), { expires })
  }

  static setText(key: string, value: any, expires) {
    return Cookies.set(key, value, { expires })
  }

  static isJson(item) {
    try {
      const parsedItem = JSON.parse(typeof item !== 'string' ? JSON.stringify(item) : item)
      if (typeof parsedItem === 'object' && parsedItem !== null) {
        return true
      }
    } catch (e) {
      return false
    }
    return false
  }
}
