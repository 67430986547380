import { useMemo } from 'react'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  productReducer,
  categoryReducer,
  cartReducer,
  orderReducer,
  orderDetails,
  settingsReducer,
  userReducer,
  searchReducer,
} from '../reducers'

const combinedReducers = {
  products: productReducer,
  categoryPrint: categoryReducer,
  cart: cartReducer,
  orderDetails,
  orders: orderReducer,
  settings: settingsReducer,
  userData: userReducer,
  search: searchReducer,
}

let store

function initStore(preloadedState = {}) {
  return createStore(
    combineReducers(combinedReducers),
    preloadedState,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  )
}

export const initializeStore = (preloadedState) => {
  let finalStore = store ?? initStore(preloadedState)

  if (preloadedState && store) {
    finalStore = initStore({
      ...store.getState(),
      ...preloadedState,
    })
    store = undefined
  }
  if (typeof window === 'undefined') return finalStore
  if (!store) store = finalStore

  return finalStore
}

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState])
  return store
}
