const categoryReducer = (state = [], action) => {
  switch (action.type) {
    case 'GET_CATEGORIES':
      return action.payload.data
    case 'CLEAR_CATEGORIES':
      return []
    default:
      return state
  }
}

export default categoryReducer
